@import "smart-grid.styl"

@import "mixin-lib.styl"
@font-face
    font-family RobotoRegular
    font-style normal
    src url("../font/RobotoRegular/RobotoRegular.ttf")

@font-face
    font-family RobotoCondensedBold
    font-style normal
    src url("../font/RobotoCondensedBold/RobotoCondensedBold.ttf")



$blue = #3d4e62
$liteBlue = #58ace2
$red = #dd0032
$litenBlue = #1faef2
$yellow = #facb00
$fontColor = #656565
$seansBlue = #21adf2
$gray = #c6c6c6
$grayDark = #8c8c8c
$grayBorder = #e4e4e4
$lightGray = #f9f9f9
$cream = #f8f5ef
$shadow = 0 2px 10px rgba(($blue)0.8)
$shadowGray = 0.3em 0.3em 0.7em 0 rgba(200, 200, 200, 0.5);

$timeTrans= 0.25s


.cl
    clearfix()

reset()

h1, h2, h3
    color $liteBlue
    font-family RobotoCondensedBold

h1
    font-size 1.8rem
    line-height 3rem
h2
    font-size 1.4rem
h3
    font-size 1.2rem

b, strong
    font-weight bold
//.debug
//	debug(rgba(0,0,0,0.5),1px solid red)

html, body
    height 100%
    box-sizing border-box


body
    font-family "RobotoRegular", sans-serif

a
    text-decoration none

p
    margin-bottom 0.4rem
    color $fontColor
    line-height 1.5rem
ul li
    color $fontColor



button
    background: $liteBlue
    color #ffffff
    border 0
    width 100%
    cursor pointer
    transition background $timeTrans
    border-radius 2px
    &:hover
        background $blue




input, textarea
    border 1px solid $grayBorder
    padding 0 5px
    &::placeholder
        color:$gray


.deco
    color $blue
    font-weight bold



.mainPage__content ul
    margin-left 30px
    li
        padding 5px 0
        list-style disc









.wrapper
    wrapper()








.headerLine
    position relative

    &__img
        background url(/img/bgOut.jpg) $blue
        position absolute
        left 0
        top 0
        z-index -2
        height 100%
        width 100%


header
    row-flex()
    padding 35px 0


.header__logo
    col()
    col-3()
    col-sm-12()
    sm text-align center
    sm margin-top 10px
    sm margin-bottom 10px





.header__inform__box
    col()
    col-6()
    col-sm-12()
    sm text-align center
    sm margin-top 10px
    sm margin-bottom 10px

    &__title
        text-transform uppercase
        color $blue
        padding-bottom: 5px
        font-weight bold
        opacity .7
        position relative


    &__line
        border-bottom 2px solid $seansBlue

    .phoneMark
        width 100%
        color $blue
        md font-size 0.82rem


.headerCart
    col()
    col-3()
    col-sm-12()
    //background pink
    text-align right
    sm text-align center
    sm margin-top 10px
    sm margin-bottom 10px
    &__head
        color $blue
        font-weight bold
    &__full_inf
        color $grayDark

    &__full__btn
        margin auto
        sm text-align center
        padding 15px 0
    &__btn
        width 180px
        display block
        margin-right 0px
        margin-left auto
        margin-top 5px
        margin-bottom 5px
        text-align center
        padding-top 5px
        padding-bottom 5px
        sm margin-left auto
        sm margin-right auto





.itm_accent
    font-size 1rem
    font-weight bold
    color $blue








.mainPage
    &__header
        margin-bottom 15px
    &__title
        text-align left
        color $blue
        margin 5px 0
        display inline
        border-bottom 3px solid $blue



.mainPage__nav
    row-flex()

    &__item
        col()
        col-2()
        col-md-6()
        text-align center
        cursor pointer
        border 1px solid $grayBorder
        margin-bottom 15px
        transition transform $timeTrans
        &:hover
            transform scale(1.04)

        &__title
            text-align center
            text-transform uppercase
            font-family RobotoCondensedBold
            background $seansBlue
            padding 4px 5px
            a
                color #ffffff




.mainPage__content

    padding 10px 0





.catPage, .positionPage, .orderPage
    row-flex()



.catPage__navBlock
    col()
    col-3()
    col-sm-12()
    order 0
    position relative
    &__certificate
        row-float()
        col-12()
        text-align center
        margin-top: 10px

.certificateBox
    col-float()
    col-12()
    col-lg-6()
    col-sm-3()
    img
        display: block
        min-height: 1px
        margin: 8px auto
        border 1px solid #e3e3e3;
        border-radius 4px
        box-shadow $shadowGray
        width 80%
        lg width 94%



.catPage__content, .posWrapper
    col()
    col-12()






.filter_row
    display inline-block





.checkbox {
    vertical-align: top;
    width: 17px;
    height: 17px;
    margin: 0 3px 0 0;
}
.checkbox + label {
    cursor: pointer;
}
.checkbox:not(checked) {
    position: absolute;
    opacity: 0;
}
.checkbox:not(checked) + label {
    position: relative;
    padding: 0 0 0 35px;
    sm padding-left 25px;
}
.checkbox:not(checked) + label:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    border: 1px solid $gray; //#CDD1DA
    border-radius: 50%;
    background: #FFF;
}
.checkbox:not(checked) + label:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $seansBlue;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.5);
    opacity: 0;
    transition: all .2s;
}
.checkbox:checked + label:after {
    opacity: 1;
}
.checkbox:focus + label:before {
    box-shadow: 0 0 0 3px rgba(($seansBlue),.14);
}




.filter_block
    margin-bottom 10px
    display block






.leftMenuToggle
    margin-top 20px
    border-bottom 0
    background $liteBlue
    color #ffffff
    display inline-block
    padding 4px 10px
    font-weight bold



.colorValue
//position relative

    img


        height 25px
        position relative
        top 7px




.leftMenu
    border 1px solid $lightGray
    border-bottom  2px solid $liteBlue
    border-top  2px solid $liteBlue
    box-shadow $shadowGray
    border-bottom-left-radius 5px
    border-bottom-right-radius 5px
    padding-bottom 2px
    ul li a
        display block
        padding 4px 2px 4px 10px
        border-top 1px $lightGray solid
        border-bottom 1px $lightGray solid
        color $blue
        font-size 0.94rem
        transition color $timeTrans, background $timeTrans
        &:hover
            background darken($lightGray, 1)
            color $seansBlue


.breadcrumbs
    ul li
        display inline-block
        padding-right 5px
        a
            color $blue
            transition color $timeTrans
            &:hover
                color $seansBlue

.sorting
    row-flex()
    col-padding()
    padding-top 2px
    color #ffffff
    text-align center
    lg font-size 0.8rem
    font-size 0.88rem
    &__box
        col()
        col-4()
        text-align left
        label
            md display block
        select
            md width 100%

#shk_prodHelper
    padding 5px 10px
    border 2px solid $lightGray
    position: absolute
    z-index:100
    background-color:#fff
    width 170px
    display:none
    box-shadow: $shadow
    border-radius:4px





.shk_loading
    width:30px
    height:30px
    position: absolute
    z-index:100
    background:url('../img/ajax-loader.gif') center center no-repeat #fff;
    border:1px solid $lightGray
    box-shadow $shadow
    display: none
    border-radius:4px

#shk_prodHelper
    div
        padding:5px 0
        text-align: center

.relative
    width:100%
position:relative
.error
    color red
.price-add
    colorb red
    padding-left 3px
    line-height 0
.shk_count_button
    width 26px
    height 26px
    white-space nowrap
    padding 0
    margin 0 4px
.shk-but
    margin 0 4px
.shk-count
    padding 2px
    text-align center


#shk_buttons
    row-float()
    #shk_confirmButton
        col()
        col-6()
        float left
        padding 2px 5px
    #shk_cancelButton
        col()
        col-6()
        float right
        padding 2px 5px


.srting__block
    background $liteBlue
    line-height 32px
    box-shadow $shadowGray
    margin-bottom 5px
    padding-bottom 1px

#products
    row-flex()



.product
    col()
    col-3()
    col-md-4()
    col-sm-6()
    col-xs-12()
    padding 5px
    margin-top 5px
    margin-bottom 5px
    border 1px solid $grayBorder
    display flex
    flex-direction column
    &__title
        padding-bottom 15px
        a
            color $seansBlue
            display block
    &__img
        transition transform $timeTrans
        margin-top auto
        &:hover
            transform scale(0.96)
        img
            display block
            min-height: 1px
            margin 0 auto

    &__bottom
        min-height: 1px
        margin-top auto

    &__action
        display flex
        margin-left -1%
        margin-right -1%
        &__left
            col()
            col-6()
            display flex
            justify-content flex-end
            position relative
        &__priceOld
            col()
            col-12()
            font-size 1.06rem
            md font-size 0.88rem
            sm font-size 0.98rem
            color $gray
            text-decoration line-through
            position absolute
            bottom 25px




        &__price
            col()
            col-12()


            font-size 1.12rem
            line-height 2rem
            font-weight bold
            md font-size 1rem
            sm font-size 1.08rem

        &__right
            col()
            col-6()


        &__button
            line-height 2rem
            font-size 1.12rem
            md font-size 1rem
            sm font-size 1.08rem



.paginations

    margin-top 20px
    line-height 32px
    text-align center
    background $liteBlue
    ul
        li
            display inline-block
            color white
            a
                color white
                padding 3px 8px
                display block
                xs font-size 0.9rem
                xs padding-left  4px
                xs padding-right  4px
                &:hover
                    color $blue
                &.active
                    color $seansBlue
                    background #fff





.posWrapper
    col()
    col-12()



.pos
    row-flex()
    col()
    col-12()
    margin 0 -1%


.pos
    .breadcrumbs
        col()
        col-12()
    &__h2-title
        border-bottom 1px solid $grayBorder
        line-height 34px
        margin-bottom 15px


.pos__img
    col()
    col-4()
    col-md-6()
    col-sm-12()
    order 0
    sm order 1
    margin-top 0.5rem
    img
        border 2px solid $lightGray
        border-radius 4px
        padding 4px
        display block
        margin 0 auto
        transition transform $timeTrans
        &:hover
            transform scale(0.96)



.pos__inf
    col()
    col-8()
    col-md-6()
    col-sm-12()
    order 1
    sm order 0
    &__title
        text-align left
        sm text-align center
    .pos__article
        color $gray
        sm text-align center
        &__title
            font-style italic
            text-decoration none

    &__function
        border 1px solid $grayBorder
        padding 10px 0
        margin 10px 0
        width 300px
        box-sizing border-box
        text-align center
        sm margin-left auto
        sm margin-right auto

        &__priceOld
            display inline-block
            color $gray
            text-decoration line-through
            font-size 1.2rem
            line-height 2rem

        &__price
            display inline-block
            font-size 1.2rem
            line-height 2rem
            font-weight bold
        &__button
            margin-top 20px
            width 200px
            line-height 32px
            font-size 20px




.formCallPos
    width 300px
    sm margin-left auto
    sm margin-right auto


.positionPage__callBack
    sm width 70%
    xs width  100%
    margin 0 auto
    &__button
        line-height 24px
        font-size 16px
        width 180px
        display block
        margin auto



    &__label
        line-height 34px
        font-size 16px
        width 300px
        display block
        box-sizing border-box
        margin-bottom 5px


.pos__inf__tech
    padding 0 10px
    table
        margin 0 auto
        width 80%
        lg width 90%
        sm width 80%
        xs width 98%
        tr
            width 100%
            transition background $timeTrans
            &:hover
                background darken($lightGray, 1)
                cursor pointer
        td
            padding-left 5px
            line-height 2rem
        td:first-child
            border-right  1px solid $gray
            border-bottom  1px solid $gray
        td:last-child
            border-bottom  1px solid $gray




.pos__about
    col()
    col-12()
    margin-top 20px



.comments__position
    padding 20px 0
    .form-group
        label
            display block
            font-weight bold
            margin-bottom 10px
            color $blue
            font-size 1.12rem
        input
            width 200px
    .form-actions
        input

            font-size 18px
            background $liteBlue
            color #fff
            border none
            padding 5px 20px
            &:hover
                background $blue


.moreProduct
    col()
    col-12()
    margin 20px 0
    &__title
        font-size 1.4rem
        font-weight bold
        color $liteBlue
        border-bottom 2px solid $liteBlue
    &__wrapper
        row-flex()
        justify-content(center)
        margin 10px 0


    &__box
        row-flex()
        col()
        col-3()
        col-xs-5()



        flex-direction(column)
        justify-content center
        border 2px solid $lightGray
        box-shadow $shadowGray
        padding 4px
        border-radius 4px
        transition transform $timeTrans
        overflow hidden



        &:hover
            transform scale(1.04)
        &__img
            margin-top auto
            min-height: 1px


            display: block
            max-width:100%

            img
                display: block
                margin 0 auto


        &__title
            text-align center
            color $seansBlue
            display: block
            min-height: 1px













.price
    &__actual
        text-align left
        sm text-align center

    &__text
        color #21adf2
        border-bottom 2px solid #21adf2
        font-size 1.12rem























.orderPage
    row-flex()

    &__title
        text-align center
        width 100%
    &__leftBlock
        col()
        col-6()
        col-sm-12()

        h2
            border-bottom 2px solid $grayBorder
        &__inf
            font-size 0.88rem
            background $grayBorder
            text-align center
            font-style italic
            color $fontColor
        table
            margin 0 auto 15px auto
            width 80%
            md width 100%
            sm width 70%
            xs width 82%



        tr
            col()
            col-12()

            line-height 48px
            td:first-child
                width 30%
            td:last-child
                text-align center
                width 70%
            input
                display block
                width 94%
                line-height 34px





    &__rightBlock
    //border 1px solid green
        col()
        col-6()
        col-sm-12()
        h2
            border-bottom 2px solid $grayBorder
        table
            tr
                border-bottom 2px solid $grayBorder
                background $lightGray

                td
                    border 1px solid #ffffff
                    border-bottom 0
                    border-top 0
        .cart-order
            text-align center
            &__title
                color $blue
                font-size 0.8em
                font-weight bold
            &__img
                width 80%
                margin 0 auto
                display block
                border 1px solid $lightGray
                border-radius 4px

.orderPage__totalPrice
    margin-top 10px
    text-align center
    i
        border-bottom 2px solid $seansBlue


.buttonOrder
    background $liteBlue
    color #ffffff
    border 0
    cursor pointer
    transition background $timeTrans
    padding 3px 15px
    &:hover
        background $blue









.priceWrap
    row-flex()
    justify-content space-around
    margin 50px 0
    .priceItem
        col()
        col-4()
        text-align center
        a
            background $seansBlue
            padding 10px 30px
            color white
            transition $timeTrans
            &:hover
                background $blue




















.footerLine
    width 100%
    background $blue
    border-top $liteBlue 4px solid

.footer
    row-flex()
    padding 10px 0
    &__line
        border-bottom 2px solid $seansBlue
    &__title
        text-transform uppercase
        color #fff
        padding-bottom: 5px
        font-weight bold
        opacity .7
        position relative
    &__schedule
        width 100%
        color #fff







.menuFooter
    col()
    col-3()
    col-sm-12()
    ul li a
        color #fff
        padding 5px 0
        display block
        &:hover
            color $liteBlue




.footer__col
    col()
    col-3()
    col-sm-12()
    overflow hidden
    &__line
        border-bottom 2px solid $seansBlue


.footer__email
    col()
    col-3()
    col-sm-12()
    overflow hidden
    font-size 0.84rem
    md font-size 0.68rem
    md line-height 1.2rem
    sm font-size .6rem
    sm line-height 1.4rem
    color #fff
    &__title
        text-transform uppercase
        color #fff
        padding-bottom: 5px
        font-weight bold
        opacity .7
        position relative
    &__line
        border-bottom 2px solid $seansBlue
    ul li
        padding 10px 0 0
        color #fff
        opacity .5
        padding-left 20px
        padding-right -20px

        a
            color #ffffff
            text-decoration underline

    .mailMark
        background url(/img/mailMarker.png) no-repeat 0 10px;
        width 100%

.footer__logo
    col()
    col-3()
    col-sm-12()
    opacity .5



.contentPage
    padding-top 120px

.senksMassage
    width 380px
    border 2px solid $blue;
    border-radius 8px;
    margin 0 auto
    padding 10px
    h1
        text-align center
    img
        margin 0 auto
        display block
    ul
        text-align center
        margin 10px 0

    .linkOnMain
        text-align center
        a
            padding 5px
            margin 10px

    .important
        font-weight bold
        font-style italic

    .signature
        margin 10px 0
        width 160px
        float right
        color $blue
        font-style italic
        font-size 0.8rem
        text-align right

//БЭМ
.layout
    &__middleMenu
        wrapper()
        margin-bottom 15px



.upLine
    width 100%
    border-bottom 1px solid $grayBorder
    &__inner
        wrapper()


.topMenu
    &__control
        display none
        md display block
        padding-left 40px
        background url("/img/sandwich__ico.png")        no-repeat 5px 50%
        text-decoration none
        color $blue
        font-size 20px
        trns(color)
        line-height 34px
        &:hover
            color $liteBlue

    &__drop
        md position absolute
        background #ffffff
        z-index 1000

        &--hidden
            md max-height 0
            overflow hidden
            transition max-height $timeTrans ease-in-out
        &--visible
            height auto
            max-height 400px
            overflow hidden
            transition max-height $timeTrans ease-in-out
            box-shadow 0px 2px 1px 0px rgba(0,0,0,0.75)


    &__link
        display inline-block
        text-decoration none
        padding 5px 15px
        color $grayDark
        trns(background, color)
        font-size 16px
        z-index 1001
        md display block
        md background-color #fff
        md color $liteBlue
        md margin-bottom 2px
        md font-size 16px
        md padding-left 40px
        md padding-right 60px
        &--first
            sm margin-top 2px
        &:hover
            background $blue
            color #fff












.middleMenu
    &__control
        display none
        md display block
        padding-left 40px
        padding-top 5px
        padding-bottom 5px
        background $blue url("/img/sandwich__ico.png") no-repeat 5px 50%
        text-decoration none
        color $liteBlue
        font-size 22px

        &:hover
            background-color $yellowLight
    &__drop
        border-bottom 1px solid $grayBorder
        border-top 1px solid $grayBorder
        md border none
        md background #fff
        md position absolute
        z-index 1000
        &--hidden
            md max-height 0
            overflow hidden
            transition max-height $timeTrans ease-in-out
        &--visible
            height auto
            max-height 400px
            overflow hidden
            transition max-height $timeTrans ease-in-out


    &__link
        display inline-block
        text-decoration none
        padding 10px 45px
        color $liteBlue
        trns(background)
        font-size 16px
        md display block
        md background-color $blue
        md margin-bottom 2px
        md font-size 16px
        md padding-left 40px
        md padding-right 100px
        &--first
            sm margin-top 2px
        &:hover
            background $blue
.btn
    &__confirm
        background $blue
        color #fff
        trns(background)
        &:hover
            background $liteBlue


    &__cancel
        background $red
        color #fff
        trns(background)
        &:hover
            background lighten($red, 30)

