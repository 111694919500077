@font-face {
  font-family: RobotoRegular;
  font-style: normal;
  src: url("../font/RobotoRegular/RobotoRegular.ttf");
}

@font-face {
  font-family: RobotoCondensedBold;
  font-style: normal;
  src: url("../font/RobotoCondensedBold/RobotoCondensedBold.ttf");
}

.cl::after {
  display: block;
  content: "";
  clear: both;
}

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

img {
  max-width: 100%;
}

h1,
h2,
h3 {
  color: #58ace2;
  font-family: RobotoCondensedBold;
}

h1 {
  font-size: 1.8rem;
  line-height: 3rem;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 1.2rem;
}

b,
strong {
  font-weight: bold;
}

html,
body {
  height: 100%;
  box-sizing: border-box;
}

body {
  font-family: "RobotoRegular", sans-serif;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0.4rem;
  color: #656565;
  line-height: 1.5rem;
}

ul li {
  color: #656565;
}

button {
  background: #58ace2;
  color: #fff;
  border: 0;
  width: 100%;
  cursor: pointer;
  transition: background 0.25s;
  border-radius: 2px;
}

button:hover {
  background: #3d4e62;
}

input,
textarea {
  border: 1px solid #e4e4e4;
  padding: 0 5px;
}

input::placeholder,
textarea::placeholder {
  color: #c6c6c6;
}

.deco {
  color: #3d4e62;
  font-weight: bold;
}

.mainPage__content ul {
  margin-left: 30px;
}

.mainPage__content ul li {
  padding: 5px 0;
  list-style: disc;
}

.wrapper {
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

.headerLine {
  position: relative;
}

.headerLine__img {
  background: url("/img/bgOut.jpg") #3d4e62;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
  height: 100%;
  width: 100%;
}

header {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
  padding: 35px 0;
}

.header__logo {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 23%;
}

.header__inform__box {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 48%;
}

.header__inform__box__title {
  text-transform: uppercase;
  color: #3d4e62;
  padding-bottom: 5px;
  font-weight: bold;
  opacity: 0.7;
  position: relative;
}

.header__inform__box__line {
  border-bottom: 2px solid #21adf2;
}

.header__inform__box .phoneMark {
  width: 100%;
  color: #3d4e62;
}

.headerCart {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 23%;
  text-align: right;
}

.headerCart__head {
  color: #3d4e62;
  font-weight: bold;
}

.headerCart__full_inf {
  color: #8c8c8c;
}

.headerCart__full__btn {
  margin: auto;
  padding: 15px 0;
}

.headerCart__btn {
  width: 180px;
  display: block;
  margin-right: 0px;
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.itm_accent {
  font-size: 1rem;
  font-weight: bold;
  color: #3d4e62;
}

.mainPage__header {
  margin-bottom: 15px;
}

.mainPage__title {
  text-align: left;
  color: #3d4e62;
  margin: 5px 0;
  display: inline;
  border-bottom: 3px solid #3d4e62;
}

.mainPage__nav {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
}

.mainPage__nav__item {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 14.666666666666668%;
  text-align: center;
  cursor: pointer;
  border: 1px solid #e4e4e4;
  margin-bottom: 15px;
  transition: transform 0.25s;
}

.mainPage__nav__item:hover {
  transform: scale(1.04);
}

.mainPage__nav__item__title {
  text-align: center;
  text-transform: uppercase;
  font-family: RobotoCondensedBold;
  background: #21adf2;
  padding: 4px 5px;
}

.mainPage__nav__item__title a {
  color: #fff;
}

.mainPage__content {
  padding: 10px 0;
}

.catPage,
.positionPage,
.orderPage {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
}

.catPage__navBlock {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 23%;
  order: 0;
  position: relative;
}

.catPage__navBlock__certificate {
  margin-left: -1%;
  margin-right: -1%;
  width: 98%;
  text-align: center;
  margin-top: 10px;
}

.catPage__navBlock__certificate::after {
  display: block;
  content: "";
  clear: both;
}

.certificateBox {
  float: left;
  width: 98%;
}

.certificateBox img {
  display: block;
  min-height: 1px;
  margin: 8px auto;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: 0.3em 0.3em 0.7em 0 rgba(200,200,200,0.5);
  width: 80%;
}

.catPage__content,
.posWrapper {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 98%;
}

.filter_row {
  display: inline-block;
}

.checkbox {
  vertical-align: top;
  width: 17px;
  height: 17px;
  margin: 0 3px 0 0;
}

.checkbox + label {
  cursor: pointer;
}

.checkbox:not(checked) {
  position: absolute;
  opacity: 0;
}

.checkbox:not(checked) + label {
  position: relative;
  padding: 0 0 0 35px;
}

.checkbox:not(checked) + label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #c6c6c6;
  border-radius: 50%;
  background: #fff;
}

.checkbox:not(checked) + label:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #21adf2;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.5);
  opacity: 0;
  transition: all 0.2s;
}

.checkbox:checked + label:after {
  opacity: 1;
}

.checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(33,173,242,0.14);
}

.filter_block {
  margin-bottom: 10px;
  display: block;
}

.leftMenuToggle {
  margin-top: 20px;
  border-bottom: 0;
  background: #58ace2;
  color: #fff;
  display: inline-block;
  padding: 4px 10px;
  font-weight: bold;
}

.colorValue img {
  height: 25px;
  position: relative;
  top: 7px;
}

.leftMenu {
  border: 1px solid #f9f9f9;
  border-bottom: 2px solid #58ace2;
  border-top: 2px solid #58ace2;
  box-shadow: 0.3em 0.3em 0.7em 0 rgba(200,200,200,0.5);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-bottom: 2px;
}

.leftMenu ul li a {
  display: block;
  padding: 4px 2px 4px 10px;
  border-top: 1px #f9f9f9 solid;
  border-bottom: 1px #f9f9f9 solid;
  color: #3d4e62;
  font-size: 0.94rem;
  transition: color 0.25s, background 0.25s;
}

.leftMenu ul li a:hover {
  background: #f6f6f6;
  color: #21adf2;
}

.breadcrumbs ul li {
  display: inline-block;
  padding-right: 5px;
}

.breadcrumbs ul li a {
  color: #3d4e62;
  transition: color 0.25s;
}

.breadcrumbs ul li a:hover {
  color: #21adf2;
}

.sorting {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
  padding-left: 1%;
  padding-right: 1%;
  word-wrap: break-word;
  padding-top: 2px;
  color: #fff;
  text-align: center;
  font-size: 0.88rem;
}

.sorting__box {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 31.333333333333336%;
  text-align: left;
}

#shk_prodHelper {
  padding: 5px 10px;
  border: 2px solid #f9f9f9;
  position: absolute;
  z-index: 100;
  background-color: #fff;
  width: 170px;
  display: none;
  box-shadow: 0 2px 10px rgba(61,78,98,0.8);
  border-radius: 4px;
}

.shk_loading {
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 100;
  background: url("../img/ajax-loader.gif") center center no-repeat #fff;
  border: 1px solid #f9f9f9;
  box-shadow: 0 2px 10px rgba(61,78,98,0.8);
  display: none;
  border-radius: 4px;
}

#shk_prodHelper div {
  padding: 5px 0;
  text-align: center;
}

.relative {
  width: 100%;
}

position:relative,
.error {
  color: #f00;
}

.price-add {
  colorb: #f00;
  padding-left: 3px;
  line-height: 0;
}

.shk_count_button {
  width: 26px;
  height: 26px;
  white-space: nowrap;
  padding: 0;
  margin: 0 4px;
}

.shk-but {
  margin: 0 4px;
}

.shk-count {
  padding: 2px;
  text-align: center;
}

#shk_buttons {
  margin-left: -1%;
  margin-right: -1%;
}

#shk_buttons::after {
  display: block;
  content: "";
  clear: both;
}

#shk_buttons #shk_confirmButton {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 48%;
  float: left;
  padding: 2px 5px;
}

#shk_buttons #shk_cancelButton {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 48%;
  float: right;
  padding: 2px 5px;
}

.srting__block {
  background: #58ace2;
  line-height: 32px;
  box-shadow: 0.3em 0.3em 0.7em 0 rgba(200,200,200,0.5);
  margin-bottom: 5px;
  padding-bottom: 1px;
}

#products {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
}

.product {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 23%;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
}

.product__title {
  padding-bottom: 15px;
}

.product__title a {
  color: #21adf2;
  display: block;
}

.product__img {
  transition: transform 0.25s;
  margin-top: auto;
}

.product__img:hover {
  transform: scale(0.96);
}

.product__img img {
  display: block;
  min-height: 1px;
  margin: 0 auto;
}

.product__bottom {
  min-height: 1px;
  margin-top: auto;
}

.product__action {
  display: flex;
  margin-left: -1%;
  margin-right: -1%;
}

.product__action__left {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 48%;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.product__action__priceOld {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 98%;
  font-size: 1.06rem;
  color: #c6c6c6;
  text-decoration: line-through;
  position: absolute;
  bottom: 25px;
}

.product__action__price {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 98%;
  font-size: 1.12rem;
  line-height: 2rem;
  font-weight: bold;
}

.product__action__right {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 48%;
}

.product__action__button {
  line-height: 2rem;
  font-size: 1.12rem;
}

.paginations {
  margin-top: 20px;
  line-height: 32px;
  text-align: center;
  background: #58ace2;
}

.paginations ul li {
  display: inline-block;
  color: #fff;
}

.paginations ul li a {
  color: #fff;
  padding: 3px 8px;
  display: block;
}

.paginations ul li a:hover {
  color: #3d4e62;
}

.paginations ul li a.active {
  color: #21adf2;
  background: #fff;
}

.posWrapper {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 98%;
}

.pos {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 98%;
  margin: 0 -1%;
}

.pos .breadcrumbs {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 98%;
}

.pos__h2-title {
  border-bottom: 1px solid #e4e4e4;
  line-height: 34px;
  margin-bottom: 15px;
}

.pos__img {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 31.333333333333336%;
  order: 0;
  margin-top: 0.5rem;
}

.pos__img img {
  border: 2px solid #f9f9f9;
  border-radius: 4px;
  padding: 4px;
  display: block;
  margin: 0 auto;
  transition: transform 0.25s;
}

.pos__img img:hover {
  transform: scale(0.96);
}

.pos__inf {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 64.66666666666667%;
  order: 1;
}

.pos__inf__title {
  text-align: left;
}

.pos__inf .pos__article {
  color: #c6c6c6;
}

.pos__inf .pos__article__title {
  font-style: italic;
  text-decoration: none;
}

.pos__inf__function {
  border: 1px solid #e4e4e4;
  padding: 10px 0;
  margin: 10px 0;
  width: 300px;
  box-sizing: border-box;
  text-align: center;
}

.pos__inf__function__priceOld {
  display: inline-block;
  color: #c6c6c6;
  text-decoration: line-through;
  font-size: 1.2rem;
  line-height: 2rem;
}

.pos__inf__function__price {
  display: inline-block;
  font-size: 1.2rem;
  line-height: 2rem;
  font-weight: bold;
}

.pos__inf__function__button {
  margin-top: 20px;
  width: 200px;
  line-height: 32px;
  font-size: 20px;
}

.formCallPos {
  width: 300px;
}

.positionPage__callBack {
  margin: 0 auto;
}

.positionPage__callBack__button {
  line-height: 24px;
  font-size: 16px;
  width: 180px;
  display: block;
  margin: auto;
}

.positionPage__callBack__label {
  line-height: 34px;
  font-size: 16px;
  width: 300px;
  display: block;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.pos__inf__tech {
  padding: 0 10px;
}

.pos__inf__tech table {
  margin: 0 auto;
  width: 80%;
}

.pos__inf__tech table tr {
  width: 100%;
  transition: background 0.25s;
}

.pos__inf__tech table tr:hover {
  background: #f6f6f6;
  cursor: pointer;
}

.pos__inf__tech table td {
  padding-left: 5px;
  line-height: 2rem;
}

.pos__inf__tech table td:first-child {
  border-right: 1px solid #c6c6c6;
  border-bottom: 1px solid #c6c6c6;
}

.pos__inf__tech table td:last-child {
  border-bottom: 1px solid #c6c6c6;
}

.pos__about {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 98%;
  margin-top: 20px;
}

.comments__position {
  padding: 20px 0;
}

.comments__position .form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  color: #3d4e62;
  font-size: 1.12rem;
}

.comments__position .form-group input {
  width: 200px;
}

.comments__position .form-actions input {
  font-size: 18px;
  background: #58ace2;
  color: #fff;
  border: none;
  padding: 5px 20px;
}

.comments__position .form-actions input:hover {
  background: #3d4e62;
}

.moreProduct {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 98%;
  margin: 20px 0;
}

.moreProduct__title {
  font-size: 1.4rem;
  font-weight: bold;
  color: #58ace2;
  border-bottom: 2px solid #58ace2;
}

.moreProduct__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
  justify-content: center;
  margin: 10px 0;
}

.moreProduct__box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 23%;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #f9f9f9;
  box-shadow: 0.3em 0.3em 0.7em 0 rgba(200,200,200,0.5);
  padding: 4px;
  border-radius: 4px;
  transition: transform 0.25s;
  overflow: hidden;
}

.moreProduct__box:hover {
  transform: scale(1.04);
}

.moreProduct__box__img {
  margin-top: auto;
  min-height: 1px;
  display: block;
  max-width: 100%;
}

.moreProduct__box__img img {
  display: block;
  margin: 0 auto;
}

.moreProduct__box__title {
  text-align: center;
  color: #21adf2;
  display: block;
  min-height: 1px;
}

.price__actual {
  text-align: left;
}

.price__text {
  color: #21adf2;
  border-bottom: 2px solid #21adf2;
  font-size: 1.12rem;
}

.orderPage {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
}

.orderPage__title {
  text-align: center;
  width: 100%;
}

.orderPage__leftBlock {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 48%;
}

.orderPage__leftBlock h2 {
  border-bottom: 2px solid #e4e4e4;
}

.orderPage__leftBlock__inf {
  font-size: 0.88rem;
  background: #e4e4e4;
  text-align: center;
  font-style: italic;
  color: #656565;
}

.orderPage__leftBlock table {
  margin: 0 auto 15px auto;
  width: 80%;
}

.orderPage__leftBlock tr {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 98%;
  line-height: 48px;
}

.orderPage__leftBlock tr td:first-child {
  width: 30%;
}

.orderPage__leftBlock tr td:last-child {
  text-align: center;
  width: 70%;
}

.orderPage__leftBlock tr input {
  display: block;
  width: 94%;
  line-height: 34px;
}

.orderPage__rightBlock {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 48%;
}

.orderPage__rightBlock h2 {
  border-bottom: 2px solid #e4e4e4;
}

.orderPage__rightBlock table tr {
  border-bottom: 2px solid #e4e4e4;
  background: #f9f9f9;
}

.orderPage__rightBlock table tr td {
  border: 1px solid #fff;
  border-bottom: 0;
  border-top: 0;
}

.orderPage__rightBlock .cart-order {
  text-align: center;
}

.orderPage__rightBlock .cart-order__title {
  color: #3d4e62;
  font-size: 0.8em;
  font-weight: bold;
}

.orderPage__rightBlock .cart-order__img {
  width: 80%;
  margin: 0 auto;
  display: block;
  border: 1px solid #f9f9f9;
  border-radius: 4px;
}

.orderPage__totalPrice {
  margin-top: 10px;
  text-align: center;
}

.orderPage__totalPrice i {
  border-bottom: 2px solid #21adf2;
}

.buttonOrder {
  background: #58ace2;
  color: #fff;
  border: 0;
  cursor: pointer;
  transition: background 0.25s;
  padding: 3px 15px;
}

.buttonOrder:hover {
  background: #3d4e62;
}

.priceWrap {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
  justify-content: space-around;
  margin: 50px 0;
}

.priceWrap .priceItem {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 31.333333333333336%;
  text-align: center;
}

.priceWrap .priceItem a {
  background: #21adf2;
  padding: 10px 30px;
  color: #fff;
  transition: 0.25s;
}

.priceWrap .priceItem a:hover {
  background: #3d4e62;
}

.footerLine {
  width: 100%;
  background: #3d4e62;
  border-top: #58ace2 4px solid;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
  padding: 10px 0;
}

.footer__line {
  border-bottom: 2px solid #21adf2;
}

.footer__title {
  text-transform: uppercase;
  color: #fff;
  padding-bottom: 5px;
  font-weight: bold;
  opacity: 0.7;
  position: relative;
}

.footer__schedule {
  width: 100%;
  color: #fff;
}

.menuFooter {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 23%;
}

.menuFooter ul li a {
  color: #fff;
  padding: 5px 0;
  display: block;
}

.menuFooter ul li a:hover {
  color: #58ace2;
}

.footer__col {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 23%;
  overflow: hidden;
}

.footer__col__line {
  border-bottom: 2px solid #21adf2;
}

.footer__email {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 23%;
  overflow: hidden;
  font-size: 0.84rem;
  color: #fff;
}

.footer__email__title {
  text-transform: uppercase;
  color: #fff;
  padding-bottom: 5px;
  font-weight: bold;
  opacity: 0.7;
  position: relative;
}

.footer__email__line {
  border-bottom: 2px solid #21adf2;
}

.footer__email ul li {
  padding: 10px 0 0;
  color: #fff;
  opacity: 0.5;
  padding-left: 20px;
  padding-right: -20px;
}

.footer__email ul li a {
  color: #fff;
  text-decoration: underline;
}

.footer__email .mailMark {
  background: url("/img/mailMarker.png") no-repeat 0 10px;
  width: 100%;
}

.footer__logo {
  box-sizing: border-box;
  margin-left: 1%;
  margin-right: 1%;
  word-wrap: break-word;
  width: 23%;
  opacity: 0.5;
}

.contentPage {
  padding-top: 120px;
}

.senksMassage {
  width: 380px;
  border: 2px solid #3d4e62;
  border-radius: 8px;
  margin: 0 auto;
  padding: 10px;
}

.senksMassage h1 {
  text-align: center;
}

.senksMassage img {
  margin: 0 auto;
  display: block;
}

.senksMassage ul {
  text-align: center;
  margin: 10px 0;
}

.senksMassage .linkOnMain {
  text-align: center;
}

.senksMassage .linkOnMain a {
  padding: 5px;
  margin: 10px;
}

.senksMassage .important {
  font-weight: bold;
  font-style: italic;
}

.senksMassage .signature {
  margin: 10px 0;
  width: 160px;
  float: right;
  color: #3d4e62;
  font-style: italic;
  font-size: 0.8rem;
  text-align: right;
}

.layout__middleMenu {
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.upLine {
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
}

.upLine__inner {
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

.topMenu__control {
  display: none;
  padding-left: 40px;
  background: url("/img/sandwich__ico.png") no-repeat 5px 50%;
  text-decoration: none;
  color: #3d4e62;
  font-size: 20px;
  transition: color 0.25s;
  line-height: 34px;
}

.topMenu__control:hover {
  color: #58ace2;
}

.topMenu__drop {
  background: #fff;
  z-index: 1000;
}

.topMenu__drop--hidden {
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.topMenu__drop--visible {
  height: auto;
  max-height: 400px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
  box-shadow: 0px 2px 1px 0px rgba(0,0,0,0.75);
}

.topMenu__link {
  display: inline-block;
  text-decoration: none;
  padding: 5px 15px;
  color: #8c8c8c;
  transition: background 0.25s;
  font-size: 16px;
  z-index: 1001;
}

.topMenu__link:hover {
  background: #3d4e62;
  color: #fff;
}

.middleMenu__control {
  display: none;
  padding-left: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #3d4e62 url("/img/sandwich__ico.png") no-repeat 5px 50%;
  text-decoration: none;
  color: #58ace2;
  font-size: 22px;
}

.middleMenu__control:hover {
  background-color: $yellowLight;
}

.middleMenu__drop {
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  z-index: 1000;
}

.middleMenu__drop--hidden {
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.middleMenu__drop--visible {
  height: auto;
  max-height: 400px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.middleMenu__link {
  display: inline-block;
  text-decoration: none;
  padding: 10px 45px;
  color: #58ace2;
  transition: background 0.25s;
  font-size: 16px;
}

.middleMenu__link:hover {
  background: #3d4e62;
}

.btn__confirm {
  background: #3d4e62;
  color: #fff;
  transition: background 0.25s;
}

.btn__confirm:hover {
  background: #58ace2;
}

.btn__cancel {
  background: #dd0032;
  color: #fff;
  transition: background 0.25s;
}

.btn__cancel:hover {
  background: #ff7796;
}

@media screen and (max-width: 1200px) {
  .wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }

  .certificateBox {
    width: 48%;
  }

  .certificateBox img {
    width: 94%;
  }

  .sorting {
    font-size: 0.8rem;
  }

  .pos__inf__tech table {
    width: 90%;
  }

  .layout__middleMenu {
    padding-left: 30px;
    padding-right: 30px;
  }

  .upLine__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 992px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header__inform__box .phoneMark {
    font-size: 0.82rem;
  }

  .mainPage__nav__item {
    width: 48%;
  }

  .sorting__box label {
    display: block;
  }

  .sorting__box select {
    width: 100%;
  }

  .product {
    width: 31.333333333333336%;
  }

  .product__action__priceOld {
    font-size: 0.88rem;
  }

  .product__action__price {
    font-size: 1rem;
  }

  .product__action__button {
    font-size: 1rem;
  }

  .pos__img {
    width: 48%;
  }

  .pos__inf {
    width: 48%;
  }

  .orderPage__leftBlock table {
    width: 100%;
  }

  .footer__email {
    font-size: 0.68rem;
  }

  .footer__email {
    line-height: 1.2rem;
  }

  .layout__middleMenu {
    padding-left: 15px;
    padding-right: 15px;
  }

  .upLine__inner {
    padding-left: 15px;
    padding-right: 15px;
  }

  .topMenu__control {
    display: block;
  }

  .topMenu__drop {
    position: absolute;
  }

  .topMenu__drop--hidden {
    max-height: 0;
  }

  .topMenu__link {
    display: block;
  }

  .topMenu__link {
    background-color: #fff;
  }

  .topMenu__link {
    color: #58ace2;
  }

  .topMenu__link {
    margin-bottom: 2px;
  }

  .topMenu__link {
    font-size: 16px;
  }

  .topMenu__link {
    padding-left: 40px;
  }

  .topMenu__link {
    padding-right: 60px;
  }

  .middleMenu__control {
    display: block;
  }

  .middleMenu__drop {
    border: none;
  }

  .middleMenu__drop {
    background: #fff;
  }

  .middleMenu__drop {
    position: absolute;
  }

  .middleMenu__drop--hidden {
    max-height: 0;
  }

  .middleMenu__link {
    display: block;
  }

  .middleMenu__link {
    background-color: #3d4e62;
  }

  .middleMenu__link {
    margin-bottom: 2px;
  }

  .middleMenu__link {
    font-size: 16px;
  }

  .middleMenu__link {
    padding-left: 40px;
  }

  .middleMenu__link {
    padding-right: 100px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header__logo {
    width: 98%;
  }

  .header__logo {
    text-align: center;
  }

  .header__logo {
    margin-top: 10px;
  }

  .header__logo {
    margin-bottom: 10px;
  }

  .header__inform__box {
    width: 98%;
  }

  .header__inform__box {
    text-align: center;
  }

  .header__inform__box {
    margin-top: 10px;
  }

  .header__inform__box {
    margin-bottom: 10px;
  }

  .headerCart {
    width: 98%;
  }

  .headerCart {
    text-align: center;
  }

  .headerCart {
    margin-top: 10px;
  }

  .headerCart {
    margin-bottom: 10px;
  }

  .headerCart__full__btn {
    text-align: center;
  }

  .headerCart__btn {
    margin-left: auto;
  }

  .headerCart__btn {
    margin-right: auto;
  }

  .catPage__navBlock {
    width: 98%;
  }

  .certificateBox {
    width: 23%;
  }

  .checkbox:not(checked) + label {
    padding-left: 25px;
  }

  .product {
    width: 48%;
  }

  .product__action__priceOld {
    font-size: 0.98rem;
  }

  .product__action__price {
    font-size: 1.08rem;
  }

  .product__action__button {
    font-size: 1.08rem;
  }

  .pos__img {
    width: 98%;
  }

  .pos__img {
    order: 1;
  }

  .pos__inf {
    width: 98%;
  }

  .pos__inf {
    order: 0;
  }

  .pos__inf__title {
    text-align: center;
  }

  .pos__inf .pos__article {
    text-align: center;
  }

  .pos__inf__function {
    margin-left: auto;
  }

  .pos__inf__function {
    margin-right: auto;
  }

  .formCallPos {
    margin-left: auto;
  }

  .formCallPos {
    margin-right: auto;
  }

  .positionPage__callBack {
    width: 70%;
  }

  .pos__inf__tech table {
    width: 80%;
  }

  .price__actual {
    text-align: center;
  }

  .orderPage__leftBlock {
    width: 98%;
  }

  .orderPage__leftBlock table {
    width: 70%;
  }

  .orderPage__rightBlock {
    width: 98%;
  }

  .menuFooter {
    width: 98%;
  }

  .footer__col {
    width: 98%;
  }

  .footer__email {
    width: 98%;
  }

  .footer__email {
    font-size: 0.6rem;
  }

  .footer__email {
    line-height: 1.4rem;
  }

  .footer__logo {
    width: 98%;
  }

  .layout__middleMenu {
    padding-left: 15px;
    padding-right: 15px;
  }

  .upLine__inner {
    padding-left: 15px;
    padding-right: 15px;
  }

  .topMenu__link--first {
    margin-top: 2px;
  }

  .middleMenu__link--first {
    margin-top: 2px;
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .product {
    width: 98%;
  }

  .paginations ul li a {
    font-size: 0.9rem;
  }

  .paginations ul li a {
    padding-left: 4px;
  }

  .paginations ul li a {
    padding-right: 4px;
  }

  .positionPage__callBack {
    width: 100%;
  }

  .pos__inf__tech table {
    width: 98%;
  }

  .moreProduct__box {
    width: 39.66666666666667%;
  }

  .orderPage__leftBlock table {
    width: 82%;
  }

  .layout__middleMenu {
    padding-left: 15px;
    padding-right: 15px;
  }

  .upLine__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}