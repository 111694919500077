//Бордеры
bdrOne(color, border-radius=0)
    border 1px solid color
    if border-radius
        border-radius border-radius

bdr(side,color,size=1px, border-radius=0)
    if side == "all"
        border size solid color
        if border-radius
            border-radius border-radius
    else
        border-{side}: size solid color
        if border-radius
            border-radius border-radius


bdrAll(color,size=1px)
    border size solid color
// тени
ts(opaciti, color=$gray)
  text-shadow: 1px 1px 0px rgba(color,  opaciti);

bs(opaciti, color=$gray, colorBdr=$gray, direction=inset, thickness=-2px)
  box-shadow: 3px 3px 7px 0 rgba(color, opaciti), direction 0 thickness 0 0 colorBdr;




//Анимация
trns(obj)
  transition  obj $timeTrans