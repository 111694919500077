$columns = 12
$offset = 2%
$offset_one_side = ($offset / 2)
$atom = (100% / $columns)

$break_lg = 1200px
$break_md = 992px
$break_sm = 768px
$break_xs = 576px

reset()
    body,div,dl,dt,dd,ul,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,input,textarea,p,blockquote,th,td
        margin 0
        padding 0
    
    table
        border-collapse collapse
        border-spacing 0
    
    fieldset,img,abbr
        border 0
    
    address,caption,cite,code,dfn,em,strong,th,var
        font-style normal
        font-weight normal
    
    ul li
        list-style none
    
    caption,th
        text-align left
    
    h1,h2,h3,h4,h5,h6
        font-size 100%
        font-weight normal
    
    sup
        vertical-align text-top
    
    sub
        vertical-align text-bottom
    
    input,textarea,select
        font-family inherit
        font-size inherit
        font-weight inherit
    
    legend
        color #000
    
    article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main
        display block
    
    img
        max-width 100%
    


wrapper()
    max-width 1200px
    padding-left 30px
    padding-right 30px
    margin 0 auto

    @media screen and (max-width: $break_lg)
        padding-left 30px
        padding-right 30px
    

    @media screen and (max-width: $break_md)
        padding-left 15px
        padding-right 15px
    

    @media screen and (max-width: $break_sm)
        padding-left 15px
        padding-right 15px
    

    @media screen and (max-width: $break_xs)
        padding-left 15px
        padding-right 15px
    

row-flex()
    display flex
    flex-wrap wrap
    margin-left ($offset_one_side * -1)
    margin-right ($offset_one_side * -1)

row-float()
    margin-left ($offset_one_side * -1)
    margin-right ($offset_one_side * -1)
    clearfix()

col()
    box-sizing border-box
    margin-left $offset_one_side
    margin-right $offset_one_side
    word-wrap break-word

col-float()
    float left

col-padding()
    padding-left $offset_one_side
    padding-right $offset_one_side
    word-wrap break-word

col-1()
    width "6.333333333333334%" % ()

col-2()
    width "14.666666666666668%" % ()

col-3()
    width "23%" % ()

col-4()
    width "31.333333333333336%" % ()

col-5()
    width "39.66666666666667%" % ()

col-6()
    width "48%" % ()

col-7()
    width "56.333333333333336%" % ()

col-8()
    width "64.66666666666667%" % ()

col-9()
    width "73%" % ()

col-10()
    width "81.33333333333334%" % ()

col-11()
    width "89.66666666666667%" % ()

col-12()
    width "98%" % ()

offset($n)
    $value = $atom * $n
    margin-left $value + $offset_one_side
    margin-right $value + $offset_one_side

offset-left($n)
    $value = $atom * $n
    margin-left $value + $offset_one_side

offset-right($n)
    $value = $atom * $n
    margin-right $value + $offset_one_side

offset-padding($n)
    $value = $atom * $n
    padding-left $value + $offset_one_side
    padding-right $value + $offset_one_side

offset-left-padding($n)
    $value = $atom * $n
    padding-left $value + $offset_one_side

offset-right-padding($n)
    $value = $atom * $n
    padding-right $value + $offset_one_side

justify-content($value)
    justify-content $value

align-items($value)
    align-items $value

align-content($value)
    align-content $value

align-self($value)
    align-self $value

order($value)
    order $value

flex($value)
    flex $value

flex-grow($value)
    flex-grow $value

flex-shrink($value)
    flex-shrink $value

flex-basis($value)
    flex-basis $value

flex-direction($value)
    flex-direction $value

flex-wrap($value)
    flex-wrap $value

flex-flow($value)
    flex-flow $value

float($value)
    float $value

col-lg-1()
    @media screen and (max-width: $break_lg)
        width "6.333333333333334%" % ()
    

col-lg-2()
    @media screen and (max-width: $break_lg)
        width "14.666666666666668%" % ()
    

col-lg-3()
    @media screen and (max-width: $break_lg)
        width "23%" % ()
    

col-lg-4()
    @media screen and (max-width: $break_lg)
        width "31.333333333333336%" % ()
    

col-lg-5()
    @media screen and (max-width: $break_lg)
        width "39.66666666666667%" % ()
    

col-lg-6()
    @media screen and (max-width: $break_lg)
        width "48%" % ()
    

col-lg-7()
    @media screen and (max-width: $break_lg)
        width "56.333333333333336%" % ()
    

col-lg-8()
    @media screen and (max-width: $break_lg)
        width "64.66666666666667%" % ()
    

col-lg-9()
    @media screen and (max-width: $break_lg)
        width "73%" % ()
    

col-lg-10()
    @media screen and (max-width: $break_lg)
        width "81.33333333333334%" % ()
    

col-lg-11()
    @media screen and (max-width: $break_lg)
        width "89.66666666666667%" % ()
    

col-lg-12()
    @media screen and (max-width: $break_lg)
        width "98%" % ()
    

offset-lg($n)
    @media screen and (max-width: $break_lg)
        $value = $atom * $n
        margin-left $value + $offset_one_side
        margin-right $value + $offset_one_side
    

offset-left-lg($n)
    @media screen and (max-width: $break_lg)
        $value = $atom * $n
        margin-left $value + $offset_one_side
    

offset-right-lg($n)
    @media screen and (max-width: $break_lg)
        $value = $atom * $n
        margin-right $value + $offset_one_side
    

offset-padding-lg($n)
    @media screen and (max-width: $break_lg)
        $value = $atom * $n
        padding-left $value + $offset_one_side
        padding-right $value + $offset_one_side
    

offset-left-padding-lg($n)
    @media screen and (max-width: $break_lg)
        $value = $atom * $n
        padding-left $value + $offset_one_side
    

offset-right-padding-lg($n)
    @media screen and (max-width: $break_lg)
        $value = $atom * $n
        padding-right $value + $offset_one_side
    

justify-content-lg($value)
    @media screen and (max-width: $break_lg)
        justify-content $value
    

align-items-lg($value)
    @media screen and (max-width: $break_lg)
        align-items $value
    

align-content-lg($value)
    @media screen and (max-width: $break_lg)
        align-content $value
    

align-self-lg($value)
    @media screen and (max-width: $break_lg)
        align-self $value
    

order-lg($value)
    @media screen and (max-width: $break_lg)
        order $value
    

flex-lg($value)
    @media screen and (max-width: $break_lg)
        flex $value
    

flex-grow-lg($value)
    @media screen and (max-width: $break_lg)
        flex-grow $value
    

flex-shrink-lg($value)
    @media screen and (max-width: $break_lg)
        flex-shrink $value
    

flex-basis-lg($value)
    @media screen and (max-width: $break_lg)
        flex-basis $value
    

flex-direction-lg($value)
    @media screen and (max-width: $break_lg)
        flex-direction $value
    

flex-wrap-lg($value)
    @media screen and (max-width: $break_lg)
        flex-wrap $value
    

flex-flow-lg($value)
    @media screen and (max-width: $break_lg)
        flex-flow $value
    

float-lg($value)
    @media screen and (max-width: $break_lg)
        float $value
    

lg($name, $value)
    @media screen and (max-width: $break_lg)
        {$name} $value
    

col-md-1()
    @media screen and (max-width: $break_md)
        width "6.333333333333334%" % ()
    

col-md-2()
    @media screen and (max-width: $break_md)
        width "14.666666666666668%" % ()
    

col-md-3()
    @media screen and (max-width: $break_md)
        width "23%" % ()
    

col-md-4()
    @media screen and (max-width: $break_md)
        width "31.333333333333336%" % ()
    

col-md-5()
    @media screen and (max-width: $break_md)
        width "39.66666666666667%" % ()
    

col-md-6()
    @media screen and (max-width: $break_md)
        width "48%" % ()
    

col-md-7()
    @media screen and (max-width: $break_md)
        width "56.333333333333336%" % ()
    

col-md-8()
    @media screen and (max-width: $break_md)
        width "64.66666666666667%" % ()
    

col-md-9()
    @media screen and (max-width: $break_md)
        width "73%" % ()
    

col-md-10()
    @media screen and (max-width: $break_md)
        width "81.33333333333334%" % ()
    

col-md-11()
    @media screen and (max-width: $break_md)
        width "89.66666666666667%" % ()
    

col-md-12()
    @media screen and (max-width: $break_md)
        width "98%" % ()
    

offset-md($n)
    @media screen and (max-width: $break_md)
        $value = $atom * $n
        margin-left $value + $offset_one_side
        margin-right $value + $offset_one_side
    

offset-left-md($n)
    @media screen and (max-width: $break_md)
        $value = $atom * $n
        margin-left $value + $offset_one_side
    

offset-right-md($n)
    @media screen and (max-width: $break_md)
        $value = $atom * $n
        margin-right $value + $offset_one_side
    

offset-padding-md($n)
    @media screen and (max-width: $break_md)
        $value = $atom * $n
        padding-left $value + $offset_one_side
        padding-right $value + $offset_one_side
    

offset-left-padding-md($n)
    @media screen and (max-width: $break_md)
        $value = $atom * $n
        padding-left $value + $offset_one_side
    

offset-right-padding-md($n)
    @media screen and (max-width: $break_md)
        $value = $atom * $n
        padding-right $value + $offset_one_side
    

justify-content-md($value)
    @media screen and (max-width: $break_md)
        justify-content $value
    

align-items-md($value)
    @media screen and (max-width: $break_md)
        align-items $value
    

align-content-md($value)
    @media screen and (max-width: $break_md)
        align-content $value
    

align-self-md($value)
    @media screen and (max-width: $break_md)
        align-self $value
    

order-md($value)
    @media screen and (max-width: $break_md)
        order $value
    

flex-md($value)
    @media screen and (max-width: $break_md)
        flex $value
    

flex-grow-md($value)
    @media screen and (max-width: $break_md)
        flex-grow $value
    

flex-shrink-md($value)
    @media screen and (max-width: $break_md)
        flex-shrink $value
    

flex-basis-md($value)
    @media screen and (max-width: $break_md)
        flex-basis $value
    

flex-direction-md($value)
    @media screen and (max-width: $break_md)
        flex-direction $value
    

flex-wrap-md($value)
    @media screen and (max-width: $break_md)
        flex-wrap $value
    

flex-flow-md($value)
    @media screen and (max-width: $break_md)
        flex-flow $value
    

float-md($value)
    @media screen and (max-width: $break_md)
        float $value
    

md($name, $value)
    @media screen and (max-width: $break_md)
        {$name} $value
    

col-sm-1()
    @media screen and (max-width: $break_sm)
        width "6.333333333333334%" % ()
    

col-sm-2()
    @media screen and (max-width: $break_sm)
        width "14.666666666666668%" % ()
    

col-sm-3()
    @media screen and (max-width: $break_sm)
        width "23%" % ()
    

col-sm-4()
    @media screen and (max-width: $break_sm)
        width "31.333333333333336%" % ()
    

col-sm-5()
    @media screen and (max-width: $break_sm)
        width "39.66666666666667%" % ()
    

col-sm-6()
    @media screen and (max-width: $break_sm)
        width "48%" % ()
    

col-sm-7()
    @media screen and (max-width: $break_sm)
        width "56.333333333333336%" % ()
    

col-sm-8()
    @media screen and (max-width: $break_sm)
        width "64.66666666666667%" % ()
    

col-sm-9()
    @media screen and (max-width: $break_sm)
        width "73%" % ()
    

col-sm-10()
    @media screen and (max-width: $break_sm)
        width "81.33333333333334%" % ()
    

col-sm-11()
    @media screen and (max-width: $break_sm)
        width "89.66666666666667%" % ()
    

col-sm-12()
    @media screen and (max-width: $break_sm)
        width "98%" % ()
    

offset-sm($n)
    @media screen and (max-width: $break_sm)
        $value = $atom * $n
        margin-left $value + $offset_one_side
        margin-right $value + $offset_one_side
    

offset-left-sm($n)
    @media screen and (max-width: $break_sm)
        $value = $atom * $n
        margin-left $value + $offset_one_side
    

offset-right-sm($n)
    @media screen and (max-width: $break_sm)
        $value = $atom * $n
        margin-right $value + $offset_one_side
    

offset-padding-sm($n)
    @media screen and (max-width: $break_sm)
        $value = $atom * $n
        padding-left $value + $offset_one_side
        padding-right $value + $offset_one_side
    

offset-left-padding-sm($n)
    @media screen and (max-width: $break_sm)
        $value = $atom * $n
        padding-left $value + $offset_one_side
    

offset-right-padding-sm($n)
    @media screen and (max-width: $break_sm)
        $value = $atom * $n
        padding-right $value + $offset_one_side
    

justify-content-sm($value)
    @media screen and (max-width: $break_sm)
        justify-content $value
    

align-items-sm($value)
    @media screen and (max-width: $break_sm)
        align-items $value
    

align-content-sm($value)
    @media screen and (max-width: $break_sm)
        align-content $value
    

align-self-sm($value)
    @media screen and (max-width: $break_sm)
        align-self $value
    

order-sm($value)
    @media screen and (max-width: $break_sm)
        order $value
    

flex-sm($value)
    @media screen and (max-width: $break_sm)
        flex $value
    

flex-grow-sm($value)
    @media screen and (max-width: $break_sm)
        flex-grow $value
    

flex-shrink-sm($value)
    @media screen and (max-width: $break_sm)
        flex-shrink $value
    

flex-basis-sm($value)
    @media screen and (max-width: $break_sm)
        flex-basis $value
    

flex-direction-sm($value)
    @media screen and (max-width: $break_sm)
        flex-direction $value
    

flex-wrap-sm($value)
    @media screen and (max-width: $break_sm)
        flex-wrap $value
    

flex-flow-sm($value)
    @media screen and (max-width: $break_sm)
        flex-flow $value
    

float-sm($value)
    @media screen and (max-width: $break_sm)
        float $value
    

sm($name, $value)
    @media screen and (max-width: $break_sm)
        {$name} $value
    

col-xs-1()
    @media screen and (max-width: $break_xs)
        width "6.333333333333334%" % ()
    

col-xs-2()
    @media screen and (max-width: $break_xs)
        width "14.666666666666668%" % ()
    

col-xs-3()
    @media screen and (max-width: $break_xs)
        width "23%" % ()
    

col-xs-4()
    @media screen and (max-width: $break_xs)
        width "31.333333333333336%" % ()
    

col-xs-5()
    @media screen and (max-width: $break_xs)
        width "39.66666666666667%" % ()
    

col-xs-6()
    @media screen and (max-width: $break_xs)
        width "48%" % ()
    

col-xs-7()
    @media screen and (max-width: $break_xs)
        width "56.333333333333336%" % ()
    

col-xs-8()
    @media screen and (max-width: $break_xs)
        width "64.66666666666667%" % ()
    

col-xs-9()
    @media screen and (max-width: $break_xs)
        width "73%" % ()
    

col-xs-10()
    @media screen and (max-width: $break_xs)
        width "81.33333333333334%" % ()
    

col-xs-11()
    @media screen and (max-width: $break_xs)
        width "89.66666666666667%" % ()
    

col-xs-12()
    @media screen and (max-width: $break_xs)
        width "98%" % ()
    

offset-xs($n)
    @media screen and (max-width: $break_xs)
        $value = $atom * $n
        margin-left $value + $offset_one_side
        margin-right $value + $offset_one_side
    

offset-left-xs($n)
    @media screen and (max-width: $break_xs)
        $value = $atom * $n
        margin-left $value + $offset_one_side
    

offset-right-xs($n)
    @media screen and (max-width: $break_xs)
        $value = $atom * $n
        margin-right $value + $offset_one_side
    

offset-padding-xs($n)
    @media screen and (max-width: $break_xs)
        $value = $atom * $n
        padding-left $value + $offset_one_side
        padding-right $value + $offset_one_side
    

offset-left-padding-xs($n)
    @media screen and (max-width: $break_xs)
        $value = $atom * $n
        padding-left $value + $offset_one_side
    

offset-right-padding-xs($n)
    @media screen and (max-width: $break_xs)
        $value = $atom * $n
        padding-right $value + $offset_one_side
    

justify-content-xs($value)
    @media screen and (max-width: $break_xs)
        justify-content $value
    

align-items-xs($value)
    @media screen and (max-width: $break_xs)
        align-items $value
    

align-content-xs($value)
    @media screen and (max-width: $break_xs)
        align-content $value
    

align-self-xs($value)
    @media screen and (max-width: $break_xs)
        align-self $value
    

order-xs($value)
    @media screen and (max-width: $break_xs)
        order $value
    

flex-xs($value)
    @media screen and (max-width: $break_xs)
        flex $value
    

flex-grow-xs($value)
    @media screen and (max-width: $break_xs)
        flex-grow $value
    

flex-shrink-xs($value)
    @media screen and (max-width: $break_xs)
        flex-shrink $value
    

flex-basis-xs($value)
    @media screen and (max-width: $break_xs)
        flex-basis $value
    

flex-direction-xs($value)
    @media screen and (max-width: $break_xs)
        flex-direction $value
    

flex-wrap-xs($value)
    @media screen and (max-width: $break_xs)
        flex-wrap $value
    

flex-flow-xs($value)
    @media screen and (max-width: $break_xs)
        flex-flow $value
    

float-xs($value)
    @media screen and (max-width: $break_xs)
        float $value
    

xs($name, $value)
    @media screen and (max-width: $break_xs)
        {$name} $value
    

debug($background, $outline)
    height 100vh
    position fixed
    width 100%
    
    > div
        height inherit
        wrapper()
        
        > div
            height inherit
            row-flex()
            
            > div
                col()
                col-1()
                height inherit
                outline $outline
                background $background
            
        
    

clearfix()
    &::after
        display block
        content ""
        clear both
    

